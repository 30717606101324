import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
    //baseURL: 'http://194.182.91.111:5000'
});

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
    //baseURL: 'http://194.182.91.111:5000'
});

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
};

$authHost.interceptors.request.use(authInterceptor);

$host.interceptors.response.use(
    function (response) {
    return response;
}, function (error) {
    return Promise.reject(error?.response?.data.message);
});

$authHost.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(`interceptors.response says:${error}`)
    return Promise.reject(error.response.data.message);
});


export {
    $host,
    $authHost
}
