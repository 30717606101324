export default class InfoStore{
    constructor(){
        this._firm = {address:{}, branches:[]}
        this._intervals = []
    }
    setFirm(firm){
        this._firm = firm
    }
    get firm(){
        return this._firm
    }
    get openDays(){
        return this._firm.branches?.at(0)?.days || ''
    }
    get openHours(){
        return this._firm.branches?.at(0)?.from_to || '8:00 - 17:00'
    }
    setIntervals(intervals){
        this._intervals = intervals
    }
    get intervals(){
        return this._intervals
    }
}