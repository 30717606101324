import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import styles from "./CartItems.module.css";
import {myArray} from "../../utils/myArray";
import {dateCompare, dateToDDMMYY} from "../../utils/dateTime";
import del from "./img/icon-delete-cart.svg"
import GoogleMapHTML from "../mapComponents/GoogleMapHTML";
import {plusMinusButtonHandler} from "../basketComponents/utils/pluseMinusButtonHandler";
import InputAmount from "../UI/InputAmount";
import defaultPic from "../shopComponents/img/default-pic.svg"
import {useNavigate} from "react-router-dom";
import {Context, infoStore} from "../../index";

const CartItems = observer(() => {

    const {productStore, basketStore} = useContext(Context)

    const basket = basketStore.basket
    const products = productStore.products.filter(p => basket?.basket_products?.find(bp => bp.product_id === p.id))

    const fullPrice = basket?.basket_products?.reduce((sum, bp) => sum + bp.price * bp.amount, 0) || 0

    //console.log("Basket>>>" + JSON.stringify(basketStore.basket.basket_products, null, 2))

    const basket_products = new myArray(...basket?.basket_products)

    const navigate = useNavigate()

    const bpProductVariants = (product_id, variant_id) => {
        return new myArray(
            ...basket.basket_products.filter(f => f.product_id === product_id && f.variant_id === variant_id)
        )
    }

    const bpProductVariantTypeAdditives = (product_id, variant_id, type_id, additive_id) => {
        return basket_products.filter(f => f.product_id === product_id && f.variant_id === variant_id
            && f.type_id === type_id && f.additive_id === additive_id)
    }

    /**+ testing new vers
     let basketProducts = new myArray(...basket?.basket_products)
     basketProducts = basketProducts.map(bp =>
     ({
     ...bp,
     productWeight: `${bp.product?.name} ${bp.variant?.weight}`,
     typeAdditive: `${bp.type?.name} ${bp.additive?.name}`,
     orderDate: `${dateToDDMMYY(bp.order_date)}`,
     })
     )*/

    let bpTree = {l1: []}
    /** Building basket_products tree. See bpTreeTest below
     *
     *         l1-
     *              -l2 -
     *                   -l3
     *                   -l3
     *                   -l3
     *              -l2 -
     *                   -l3
     *                   -l3
     *             ...
     *
     */

    const l3F = (bp) => {
        const l3 = []
        bpProductVariantTypeAdditives(bp.product_id, bp.variant_id, bp.type_id, bp.additive_id)
            .forEach(bpL3 => {
                if (l3.find(d =>
                    dateCompare(d.order_date, bpL3.order_date) === 0) === undefined) {
                    l3.push({
                        name: `${dateToDDMMYY(bpL3.order_date)}`,
                        order_date: bpL3.order_date,
                        interval: bpL3.issue_time || "interval",
                        amount: bpL3.amount,
                        price: bpL3.price * bpL3.amount,
                        id: bpL3.id,
                        production_id: bpL3.production_id,
                    })
                }
            })
        return l3
    }

    const buildingBPTree = () => {
        for (const bp of basket_products) {
            if (bpTree.l1?.find(l1 => l1.product_id === bp.product_id && l1.variant_id === bp.variant_id) === undefined) {

                const l2 = []
                bpProductVariants(bp.product_id, bp.variant_id).forEach(bpL2 => {
                    if (l2.find(ta => ta.type_id === bpL2.type_id && ta.additive_id === bpL2.additive_id) === undefined) {
                        l2.push({
                            name: `${bpL2.type?.name} ${bpL2.additive?.name}`,
                            type_id: bpL2.type_id, additive_id: bpL2.additive_id,
                            id: bpL2.id,
                            l3: l3F(bpL2)
                        })
                    }
                })

                const amount = bpProductVariants(bp.product_id, bp.variant_id).reduce((sum, s) => sum + s.amount || 0, 0)
                const price = bpProductVariants(bp.product_id, bp.variant_id).reduce((sum, s) => sum + s.price * s.amount || 0., 0)
                bpTree.l1?.push({
                    amount,
                    price,
                    name: `${bp.product?.name} ${bp.variant?.weight} g`,
                    img: products.find(p => p.id === bp.product_id)?.img,
                    product_id: bp.product_id,
                    variant_id: bp.variant_id,
                    l2
                })
            } else {

            }
        }
    }
    buildingBPTree()

    const [loadingBasket, setLoadingBasket] = useState(false)
    const [clickedId, setClickedId] = useState(-1)
    const clickedDeleteButton = (basketProductId) => {
        setClickedId(basketProductId)
        plusMinusButtonHandler(basketProductId, 0, basketStore, setLoadingBasket)
    }
    const amountButtonHandler = (basketProductId, amount) => {
        plusMinusButtonHandler(basketProductId, amount, basketStore, setLoadingBasket)
    }

    //console.log("bpTree.l1 >>" + JSON.stringify(bpTree.l1, null, 2))
    useEffect(() => {
      window.scrollTo({top: 0, behavior: 'instant'});
    },[])

    return (
        <div className={styles.cont}>
            <Container fluid className="px-sm-0">
                <div className={styles.title}>Celková cena objednávky je {fullPrice} Kč</div>
                <Row>
                    <Col sm={12} md={8}>
                        <Container fluid className="px-sm-0">
                            {bpTree.l1.map(l1 =>
                                <Container fluid key={l1.name} className={`px-sm-0 ${styles.product}`}>
                                    {window.innerWidth > 768 &&
                                        <Image className={styles.basketImg}
                                               src={l1?.img ? process.env.REACT_APP_IMG_URL + l1?.img : defaultPic}
                                               alt=""
                                        />}
                                    <div className={'w-100'}>
                                        <div className={styles.productTitle}>
                                            <span>{l1.amount}x {l1.name}</span> <span>{l1.price} Kč</span>
                                        </div>

                                        {l1.l2.map(l2 =>
                                            <div key={l2.name} className={styles.productText}>
                                                <div>{l2.name}</div>
                                                <span className={styles.productText2}>K vyzvědnutí na prodejně:</span>
                                                <div className={styles.productText3}>
                                                    {l2.l3
                                                        .sort((a, b) => new Date(a.order_date) - new Date(b.order_date))
                                                        .map(l3 =>
                                                                <div key={l3.name}
                                                                     className={'d-flex justify-content-between mb-1'}>
                                                        <span
                                                            className={styles.productInterval}>{l3.name} mezi {l3.interval} hod.
                                                        </span>

                                                                    <div className={styles.amountPrice}>
                                                                        <div className={styles.productAmount}>
                                                                            <div
                                                                                className={styles.productAmount1}>{l3.amount}</div>
                                                                            <InputAmount amount={l3.amount}
                                                                                         pId={l3.production_id}
                                                                                         setAmount={(a) => amountButtonHandler(l3.id, a)}/>
                                                                        </div>
                                                                        <div className={styles.productPrice}
                                                                             onClick={() => clickedDeleteButton(l3.id)}
                                                                        > {l3.price}.-
                                                                            {loadingBasket && clickedId === l3.id ?
                                                                                <Spinner animation="border"
                                                                                         variant="primary"
                                                                                         size="sm"/>
                                                                                :
                                                                                <img className={'ms-3'} alt='delete'
                                                                                     src={del}
                                                                                     style={{cursor: 'pointer'}}
                                                                                />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Container>
                            )}
                        </Container>
                        <Container fluid className={`px-sm-0 ${styles.rectangle1}`}>
                            <div className={styles.r11}>Způsob dopravy</div>
                            <div className={'d-flex'}>
                                <div className={styles.ring}/>
                                <span className={styles.r12}>Osobní odběr v prodejně Beroun</span>
                            </div>
                        </Container>
                        <Container fluid className={`px-sm-0 ${styles.rectangle1}`}>
                            <div className={styles.r11}>Způsob platby</div>
                            <div className={'d-flex'}>
                                <div className={styles.ring}/>
                                <span className={styles.r12}>Hotově nebo kartou na prodejně</span>
                            </div>
                        </Container>
                        <Container fluid className={`px-sm-0 ${styles.rectangle1}`}>
                            <div className={styles.r11}>
                                <span>Meziúčet</span> <span>{fullPrice} Kč</span>
                            </div>
                            <div className={styles.r13}>
                                <span>Osobní odběr</span> <span>zdarma</span>
                            </div>
                            <div className={styles.r13}>
                                <span>Platba hotově nebo kartou</span> <span>zdarma</span>
                            </div>
                            <div className={styles.r13}>
                                <span>Papírová taška</span> <span>zdarma</span>
                            </div>
                            <div className={styles.r14}>
                                <span>Celková cena k úhradě</span> <span>{fullPrice} Kč</span>
                            </div>
                            <div className={styles.btns}>
                                <div className={styles.goBack}
                                     onClick={() => navigate('/')}
                                >
                                    Zpět k vyběru
                                </div>
                                <div className={styles.gotoButton}
                                     onClick={() => navigate('/checkout')}
                                >
                                    Pokračovat
                                </div>
                            </div>
                        </Container>
                    </Col>

                    <Col sm={12} md={4} className={styles.col2}>
                        <div className={styles.col2Rectangle}>
                            <div className={styles.googleMap}>
                                <GoogleMapHTML width={300} height={240}/>
                            </div>
                            <div className={styles.c22}>
                                <div className={styles.c221}>
                                    Adresa prodejny
                                </div>
                                <div className={styles.c222}>
                                    {infoStore.firm.name}
                                </div>
                                <div className={styles.c223} style={{paddingBottom: "16px"}}>
                                    {infoStore.firm.address.street}, {infoStore.firm.address.city}
                                </div>
                                <div className={styles.c224}>
                                    Otevírací doba
                                </div>
                                <div className={styles.c223}>
                                    {infoStore.openDays}
                                </div>
                                <div className={styles.c223}>
                                    {infoStore.openHours}
                                </div>
                            </div>
                            <div className={'d-flex justify-content-center my-5'}>
                                <div className={styles.gotoButton}
                                     onClick={() => navigate('/checkout')}
                                >
                                    Pokračovat
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
});

export default CartItems;

