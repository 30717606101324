import React, {useContext, useState} from 'react';
import {Context, productionStore} from "../index";
import styles from "./Shop.module.css"
import NavBar from "../components/headerComponent/NavBar";
import {Container} from "react-bootstrap";
import Footer from "../components/shopComponents/footer/Footer";
import Home from "./Home";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import CartItems from "../components/reservationComponents/CartItems";
import CheckoutForm from "../components/reservationComponents/CheckoutForm";
import ThanksForm from "../components/reservationComponents/ThanksForm";
import {loadUserBasket, useCheckBasketActivity} from "../hooks/useCheckBasketActivity";
import {loadDirectories, loadProductions, loadQualities, useLoadAll} from "../hooks/useLoadAll";
import PrivacyPolicy from "../components/privacyPolicyComponents/PrivacyPolicy";

const Shop = () => {
    const {productStore} = useContext(Context)
    const [logoX, setLogoX] = useState(0)
    const [navBarItem, setNavBarItem] = useState(0)
    const navigate = useNavigate();

    const setNavBarId = (id) => {
        navigate('/')
        setNavBarItem(id + Math.random())
    }

    const navBarMenu = productStore.products?.length ?
        window.innerWidth > 768 ?
            [
                {id: 1, name: "Sortiment"},
                {id: 2, name: "Výhody"},
                {id: 3, name: "Objednávka"},
                {id: 4, name: "Kontakt"},
            ] :
            [
                {id: 3, name: "Objednávka"},
                {id: 4, name: "Kontakt"},
            ]
        :
        [
            {id: 2, name: "Výhody"},
            {id: 4, name: "Kontakt"},
        ];

    const [loading, setLoading] = useState(true),
        [loadingBasket, setLoadingBasket] = useState(true),
        [loadingQualities, setLoadingQualities] = useState(true),
        {basketStore} = useContext(Context),
        [selectedProduct, setSelectedProduct] = useState({}),
        [selectedProductions, setSelectedProductions] = useState([]);

    useCheckBasketActivity(() => {
        loadUserBasket(basketStore, setLoadingBasket)
    }, basketStore);

    const loadAll= () => {
        loadDirectories(productStore)
        loadProductions(productStore, setLoading)
        loadQualities(productStore, setLoadingQualities)
    }

    useLoadAll(loadAll)


    const nothing2Reserve =
        productStore.products?.filter(p => p.reserve).length === 0
        || productionStore.productions.find(p => new Date(p.planned_date) > new Date()) === undefined
    if(nothing2Reserve) { // remove reservation from menu
        navBarMenu.splice(2, 1)
    }


    return (
        <Container fluid className={`px-0 ${styles.mainShopContainer}`}>
            <NavBar navBarItem={~~navBarItem}
                    navBarItemClicked={setNavBarId}
                    navBarMenu={navBarMenu}
                    logoX={logoX}
                    setLogoX={setLogoX}
            />
            <Routes>
                <Route path={'/'} element={<Home navBarItem={navBarItem}
                                                 setNavBarItem={setNavBarId}
                                                 loading={loading} loadingBasket={loadingBasket}
                                                 loadingQualities={loadingQualities}
                                                 selectedProduct={selectedProduct}
                                                 setSelectedProduct={setSelectedProduct}
                                                 selectedProductions={selectedProductions}
                                                 setSelectedProductions={setSelectedProductions}
                />}/>
                <Route path={'cart'} element={<CartItems/>}/>
                <Route path={'checkout'} element={<CheckoutForm/>}/>
                <Route path={'thanks'} element={<ThanksForm/>}/>
                <Route path={'PrivacyPolicy'} element={<PrivacyPolicy marginTop={136}/>}/>
                <Route path={'*'} element={<Navigate to={'/'}/>}/>
            </Routes>

            <Footer navBarItemClicked={setNavBarId}
                    navBarMenu={navBarMenu}
                    logoX={logoX}/>
        </Container>
    );
};

export default Shop;
