import {$host} from "./index";

export const fetchProducts = async () => {
    const {data} = await $host.get('api/product')
    return data
}
export const fetchBigProducts = async () => {
    const {data} = await $host.get('api/product/bigAll')
    return data
}

