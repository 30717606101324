import React, {useState} from 'react';
import styles from "./Calendar.module.css"
import {daysOfWeekCZ, monthNames} from "../../utils/daysOfWeek";
import {calendarArr, possibleToSelect, styleObj} from "./calendarArr";
import {dateCompare} from "../../utils/dateTime";

/**
 * Calendar component
 * @param possibleProductions - array of possible productions
 * @param closeHandler - function to close calendar
 * @param okHandler - function to confirm selected dates
 * @param sps - array of selected productions
 * @param setSps - function to set selected productions
 * @returns {Element} - calendar component
 * @constructor - Calendar
 */

const Calendar = ({possibleProductions, closeHandler, okHandler, sps, setSps}) => {
    const [date, setDate] = useState(new Date(new Date().setDate(1)))
    const possibleDates = possibleProductions?.map(p => new Date(p.planned_date))
    const today = new Date()
    let arr = calendarArr(date)

    const backClickHandler = () => {
        const newDate = new Date(date.getFullYear(), date.getMonth() - 1, 2)
        if (newDate < new Date(new Date().setDate(1))) return
        arr = calendarArr(newDate)
        setDate(newDate)
    }
    const forwardClickHandler = () => {
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDay())
        arr = calendarArr(newDate)
        setDate(newDate)
    }

    const dateClickHandler = (arrItem) => {
        //if (!arrItem.isThis) return;
        if (!possibleToSelect(today, arrItem.date, possibleDates)) return;

        const production = possibleProductions?.find(p => dateCompare(p.planned_date, arrItem.date) === 0)
        if (production) {
            if (sps.find(sp => dateCompare(sp.selectedDate, arrItem.date) === 0)) {
                // Clicked date is already selected - deselect it
                setSps(sps.filter(sp => dateCompare(sp.selectedDate, arrItem.date) !== 0))
            }
            else {
                // Clicked date is not selected - select it
                sps.find(sp=> sp.id === production.id) ?
                    setSps(sps.map(sp => sp.id === production.id ? {...sp, selectedDate: production.planned_date} : sp) )
                    :
                setSps([...sps, {...production, selectedDate: production.planned_date}])
            }
        }
    }

    return (
        <div className={styles.calendarBox}>
            <div className={styles.month}>
                <div
                    style={{cursor: 'pointer'}}
                    onClick={() => backClickHandler()}>
                    {`<`}&nbsp;&nbsp;
                </div>
                <div> {`${monthNames[date.getMonth()]}  ${date.getFullYear()}`}</div>
                <div
                    style={{cursor: 'pointer'}}
                    onClick={() => forwardClickHandler()}> &nbsp;&nbsp;{` >`}
                </div>
            </div>
            <table className={styles.table}>
                <tbody>
                <tr className={styles.week}>
                    {daysOfWeekCZ.map(i =>
                        <td key={i.name} style={{fontSize: 14, paddingBottom: 5}}>{i.name}</td>
                    )}
                </tr>
                {[0, 1, 2, 3, 4, 5].map(i =>
                    <tr key={i}>
                        {[0, 1, 2, 3, 4, 5, 6].map(j =>
                            <td
                                key={j}
                                style={styleObj(arr.at(i * 7 + j), sps.map(sp => sp.selectedDate), possibleDates, today)}
                                onClick={() => dateClickHandler(arr.at(i * 7 + j))}
                                /*onMouseEnter={() => dateBlurHandler(arr.at(i * 7 + j))}
                                onMouseLeave={() => onMouseLeaveHandler(arr.at(i * 7 + j))}*/
                                className={styles.td}
                            >
                                {arr.at(i * 7 + j).day}
                               {/* {showAmount ?
                                    <div style={{position: "fixed"}}>O</div> :
                                    <div></div>
                                }*/}
                            </td>
                        )}
                    </tr>
                )}
                </tbody>
            </table>

            <div className={styles.footer}>
                <div style={{color: '#bfbfbf'}}
                     onClick={closeHandler}
                >
                    Zrušit
                </div>
                <div style={{color: 'var(--warning)'}}
                     onClick={okHandler}
                >
                    Vybrat
                </div>
            </div>
        </div>
    );
};

export default Calendar;