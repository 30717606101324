import React from 'react';
import wheat from "../img/wheat.svg"
import styles from "./ShopQuality.module.css"
import {Container} from "react-bootstrap";


const ShopQuality = ({qualities}) => {

    return (
        <Container fluid className={styles.container}>
            <div className="shopSectionName">
                Proč vyzkoušet živý chléb?
            </div>
            <Container fluid className="px-0">
                {qualities.map((q, index) =>
                    <Container fluid key={q.id} className="px-0">
                        {index % 2 === 0 ?
                            <Container fluid className="px-0 d-flex justify-content-start">
                                <img className={styles.img} src={wheat} alt={wheat}/>
                                <Container fluid className="px-0">
                                    <Container fluid className={styles.titleR}>{q.name}</Container>
                                    <Container fluid className={styles.lineL}></Container>
                                    <Container fluid className={styles.articleR}>{q.article}</Container>
                                </Container>
                            </Container>
                            :
                            <div className="d-flex justify-content-end">
                                <Container fluid>
                                    <Container fluid className={styles.titleL}>{q.name}</Container>
                                    <Container fluid className={styles.lineR}></Container>
                                    <Container fluid className={styles.articleL}>{q.article}</Container>
                                </Container>
                                <img className={styles.img} src={wheat} alt={wheat}/>
                            </div>
                        }
                    </Container>
                )}
            </Container>
        </Container>
    )
};

export default ShopQuality;