import {dateCompare} from "../../utils/dateTime";

export const calendarArr = (anyDate) => {

    let m = anyDate.getMonth()
    let y = anyDate.getFullYear()

    const firstDate = new Date(new Date(anyDate).setDate(1))
    const prevDate = new Date(new Date(anyDate).setDate(0))
    const lastDate = new Date(y, m + 1, 0)
    const firstDateNextMonth = new Date(y, m + 1, 1)

    let firstDayOfWeek = firstDate.getDay() === 0 ? 6 : firstDate.getDay() - 1
    let prevDay = prevDate.getDate()
    let lastDay = lastDate.getDate()
    let arr = []
    let rest = 0
    for (let i = 0; i < 42; i++) {
        if (i < firstDayOfWeek) {
            const dayNumber = prevDay - firstDayOfWeek + i + 1
            const currentDate = new Date(prevDate.setDate(dayNumber))
            arr.push({isThis: false, day: dayNumber, date: currentDate})
        } else if (i < lastDay + firstDayOfWeek) {
            const dayNumber = i - firstDayOfWeek + 1
            const currentDate = new Date(firstDate.setDate(dayNumber))
            arr.push({isThis: true, day: dayNumber, date: currentDate})
        } else {
            rest++
            const currentDate = new Date(firstDateNextMonth.setDate(rest))
            arr.push({isThis: false, day: rest, date: currentDate})
        }
    }
    return arr
}

export const styleObj = (arrItem, selectedDates, possibleDates, today) => {
    let res = {
        padding: 4,

        color: selectedDates.find(d => dateCompare(arrItem.date, d) === 0 /*&& arrItem.isThis*/) ?
            `var(--warning)`
            :
            arrItem.isThis ?
                `var(--dark)`
                :
                `var(--dark)`,
        fontSize: selectedDates.find(d => dateCompare(arrItem.date, d) === 0) ? '16px' : '14px'
    }

    if (possibleToSelect(today, arrItem.date, possibleDates)) {
        res.fontWeight = "bold"
        res.cursor = 'pointer'
    } else {
        res.color = 'var(--muted)'
    }

    if (dateCompare(arrItem.date, today) === 0
        /*&& selectedDates.find(d => dateCompare(arrItem.date, d) === 0 )===undefined*/
    ) {
        res.color = selectedDates.find(d => dateCompare(arrItem.date, d) === 0) ? 'var(--warning)' : 'blue'
    }

    return res
}

export const possibleToSelect = (today, date, possibleDates) => {
    const a = dateCompare(date, today) >= 0
    const b = possibleDates.find(d => dateCompare(d, date) === 0)
    return a && b
}

