import {updateBasketAmount} from "../../../http/basketAPI";
import {productionStore} from "../../../index";

export const plusMinusButtonHandler = (basketProductId, amount, basketStore, setLoadingBasket) => {
    //console.log("clickedPlusMinusButton>>> " + basketProductId + ", "+ amount)
    const formData = new FormData()
    formData.append('id', basketProductId)
    formData.append('amount', amount)
    setLoadingBasket(true)
    const basketProduct = basketStore.basket.basket_products.find(b => b.id === basketProductId)
    updateBasketAmount(formData)
        .then(({inBasket, inShelf}) => {
            //wait(400)
            productionStore.setProductions(
                productionStore.productions.map(p=>p.id===basketProduct.production_id?{...p,amount:inShelf}:p))
            if (amount - inBasket > 0) {
                //showInfo(`Zbývají pouze dva kusy ${inShelf}ks.`)
                //console.log(amount+'; '+inBasket)
            }
            inBasket < 1 ?
                basketStore.setBasket({
                    ...basketStore.basket,
                    basket_products: basketStore.basket.basket_products
                        .filter(b => b.id !== basketProductId)
                })
                :
                basketStore.setBasket({
                    ...basketStore.basket,
                    basket_products: basketStore.basket.basket_products
                        .map(b => b.id === basketProductId ? {...b, amount:inBasket} : b)
                })

        })
        .catch(e => {
                alert(`clickedPlusMinusButton says: ${e}`)
                //setBasket({user_id: 1, basket_products: []})
            }
        )
        .finally(() => setLoadingBasket(false))
}