export default class ProductionStore {
    constructor() {
        this._productions = [{planned_date: "", product: {allergens: []}, type: {}, additive: {}}]
       // makeAutoObservable(this)
    }

    setProductions(productions) {
        this._productions = productions
    }

    get productions() {
        return this._productions
    }
}

