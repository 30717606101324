/**
 * Vrati cas do zavirani pro dany den
 * @returns leftTime: number- zbyvajici cas do zavirani (ms)
 * @param fromToArray - pole oteviraci doby pro kazdy den v tydnu
 * @example howLongUntilClosingArr( ['','','','10:00-20:00','10:00-20:00','10:00-20:00','10:00-16:00',''])
 */
const howLongUntilClosingArr = (fromToArray) => {
    const openHours = fromToArray?.at(new Date().getDay())
    return howLongUntilClosing(openHours)
}
/**
 * Vrati cas do zavirani pro dany den
 * @returns leftTime: number- zbyvajici cas do zavirani (ms)
 * @param openHours - oteviraci doba pro dany den
 * @example howLongUntilClosing('10:00-20:00')
 */
const howLongUntilClosing = (openHours) => { // howLongUntilClosing('10:00-20:00')
    const now = new Date()
    //const now = new Date(new Date().setHours(15, 3, 0, 0))

    if (!openHours) return {dateClose: now, leftTime: 0}
    const dateC = openHours.split('-')[1] || ''
    const hClose = Number(dateC.split(':')[0]) || 0
    const mClose = Number(dateC.split(':')[1]) || 0
    return  new Date((new Date()).setHours(hClose, mClose, 0, 0)) - now
}

/**
 * Minimalni cas do zavirani
 * @type {number}
 */
const leftTimeMinimum = 1000 * 60 * 60 // 1 hour TODO: Hardcoded!


/**
 * Vrati oteviraci dobu pro dany den as arr.length === 1
 * @param products
 * @param production
 * @returns {(function(*, *): string|null)[]|*[]}
 */
const todayOpenHours = (products, production) => {
    const product = products.find(p => p.id === production?.product_id)
    const variant = product?.variants.find(v => v.weight === production?.weight)
    const vt = variant?.variant_types?.find(vt => vt.type_id === production?.type_id)
    const todayOpenFromTo = (vt?.time_from && vt?.time_to) ? `${vt.time_from}-${vt.time_to}` : null
    return howLongUntilClosing(todayOpenFromTo) > leftTimeMinimum ? [todayOpenFromTo] : []
}

const isTimeUntilClosing = (products, production) => {
    if (new Date(production?.planned_date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    // today order
        return todayOpenHours(products, production).length > 0
    }
    return true
}

export {
    howLongUntilClosing,
    howLongUntilClosingArr,
    todayOpenHours,
    isTimeUntilClosing
}
