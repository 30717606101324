import {makeAutoObservable} from "mobx";

export default class ProductStore {
    constructor() {
        this._types = []
        this._additives = []
        this._allergens = []
        this._products = [{variants: [{variant_types: [{additives: []}]}], types: [], allergens: []}]
        //this._products = []
        this._page = 1
        this._totalCount = 0
        this._limit = 3
        this._currentProduct = {variants: [{variant_types: [{additives: []}]}], types: [], allergens: []}
        this._currentVariant = {variant_type_additives: [{type: [], additives: []}]}
        this._qualities = []

        makeAutoObservable(this)
    }

    setQualities(qualities) {
        this._qualities = qualities
    }

    get qualities() {
        return this._qualities
    }

    setTypes(types) {
        this._types = types
    }

    setCurrentProduct(currentProduct) {
        this._currentProduct = currentProduct
    }

    setCurrentVariant(currentVariant) {
        this._currentVariant = currentVariant
    }

    setAdditives(additives) {
        this._additives = additives
    }

    setAllergens(allergens) {
        this._allergens = allergens
    }

    setProducts(products) {
        this._products = products
    }

    setPage(page) {
        this._page = page
    }

    setTotalCount(count) {
        this._totalCount = count
    }

    get types() {
        return this._types
    }

    get currentProduct() {
        return this._currentProduct
    }

    get currentVariant() {
        return this._currentVariant
    }

    get additives() {
        return this._additives
    }

    get allergens() {
        return this._allergens
    }

    get products() {
        return this._products
    }

    get totalCount() {
        return this._totalCount
    }

    get page() {
        return this._page
    }

    get limit() {
        return this._limit
    }

    setFilter(productions) {
        const today = new Date()
        const day = today.getDay() === 0 ? 6 : today.getDay() - 1

        this._products = this._products.filter(product =>
            productions.find(p => p.product_id === product.id)
            &&
            ((product.days & 1) << day)
        )
        this._products = this._products.filter(product => product.variants.find(v =>
            productions.find(p => p.weight === v.weight)))

        this._products = this._products.filter(
            product => product.variants.find(v => v.variant_types.find(vt =>
                productions.find(p => p.type_id === vt.type_id))))

        this._products = this._products.filter(
            product => product.variants.find(v => v.variant_types.find(vt =>
                vt.additives.find(a =>
                    productions.find(p => p.additive_id === a.additive_id)))))

        return this._products
    }
}
