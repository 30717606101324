import styled from "styled-components";

export const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: red;
`;

export const MailContainer = styled.div`
  border: ${props => props.border || 'none'};
  /*width: 768px;*/
`;
export const WebSite = styled.a`
  `;


export const Stripe = styled.div`
  height: ${props => props.h || '40px'};
  width: ${props => props.w || '100%'};
  display: flex;
  align-items: center;
  background-color: ${props => props.bg || '#fff'};
  padding-left: ${props => props.pl || '0'};
  padding-right: ${props => props.pr || '0'};
  justify-content: ${props => props.jc || 'start'};
  border-left: ${props => props.bl || 'none'};
  border-right: ${props => props.br || 'none'};
  border-bottom: ${props => props.bb || 'none'};
  border-top: ${props => props.bt || 'none'};
  font-size: ${props => props.fs || '16px'};
  min-height: 18px;
  line-height: 1.5;
  
  @media (max-width: 768px) {
    width: 100%;
    font-size: ${props => props.fs || '14px'};
    line-height: 1.15;
    display: block;
    padding-top: 5px;
  }
`;
