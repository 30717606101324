import React from 'react';
import styles from "./css/ProductSelector.module.css";
import {getProductionAmount} from "../../../http/productionAPI";

const AmountBtn = ({
                       production, selectedProductions, setAmountHandler,
                       amountError, setAmountError, errorColor, setErrorColor,setTimeStyleWarning,timeStyles
                   }) => {

        const amountErrorText = 'Zbývají pouze '
        const maxAmount = parseInt(process.env.REACT_APP_MAX_NUMBER_IN_ONE_HAND) || 5

        let active = Boolean(production?.issueInterval)
        const {amountR} = selectedProductions.find(s => s.id === production.id) || {amountR: 0}
        const dashStyle = active ? `${styles.btn_m_active}` : `${styles.btn_m}`

        const clickHandler = (p, s) => {
            if (!active) {
                const {timeStyleWarning, timeStyleNormal} = timeStyles
                setTimeStyleWarning(timeStyleWarning)
                setTimeout(()=>setTimeStyleWarning(timeStyleNormal), 1000)
                return
            }
            const requiredAmount = p.amountR || 0

            if (s === '+') {
                getProductionAmount(production.id)
                    .then(crntAmount => {
                        if (crntAmount <= requiredAmount || requiredAmount >= maxAmount) {
                            setAmountError(amountErrorText + (requiredAmount >= maxAmount ? maxAmount : crntAmount) + ' ks')
                            setErrorColor('var(--warning)')
                            setTimeout(() => {
                                setErrorColor('var(--primary)')
                            }, 200)
                        } else {
                            setAmountHandler(p, s)
                        }

                    })
                    .catch(e => {
                        console.log(e)
                    })
            } else {
                if (amountError) setAmountError('')
                if (p.amountR >= 0) setAmountHandler(p, s)
            }
        }

        return (
            <div className={styles.selector}>

                <div className={styles.amount}>
                    <div className={dashStyle}
                         onClick={() => clickHandler(production, "-")}

                    >
                        <span className={active && amountR?styles.dashActive:styles.dash}
                        />
                    </div>

                    <div className={styles.btn_num}>
                        {amountR || 0}
                    </div>

                    <div className={`${styles.btn_p}`}
                         style={active ? {backgroundColor: errorColor} : {
                             backgroundColor: "var(--muted)",
                             border: 'none'
                         }}
                         onClick={() => {
                             clickHandler(production, "+")
                         }}>
                        <span className={styles.plus}><span className={styles.plus1}/></span>
                    </div>
                </div>
            </div>
        );
    }
;

export default AmountBtn;