import React from 'react';
import {ServerStyleSheet, StyleSheetManager} from "styled-components";
import {renderToString} from "react-dom/server";
import EmailBody from "./EmailBody";

const emailBodyToHtml = (basket,user) => {
    const sheet = new ServerStyleSheet();
    try {
        const html = renderToString(
            <StyleSheetManager sheet={sheet.instance}>
                <EmailBody basket={basket} user={user}/>
            </StyleSheetManager>
        );
        const styleTags = sheet.getStyleTags(); // or sheet.getStyleElement();
        const res = `<head><title> </title> <meta charset="utf-8"></head>\n${styleTags}\n${html}`
        //console.log(res);
        return (res);
    } catch (error) {
        // handle error
        console.error(error);
    } finally {
        sheet.seal();
    }

};

export default emailBodyToHtml;