export const daysOfWeekCZ = // TODO Language
    [
    {name: 'Po'},
    {name: 'Út'},
    {name: 'St'},
    {name: 'Čt'},
    {name: 'Pá'},
    {name: 'So'},
    {name: 'Ne'}
]
export const daysOfWeekDB = // Names in database
    [
    {name: 'mon'},
    {name: 'tue'},
    {name: 'wed'},
    {name: 'thu'},
    {name: 'fri'},
    {name: 'sat'},
    {name: 'sun'}
]

export const monthNames = [
    'Leden','Únor','Březen','Duben','Smět','Červen','Červenec','Srpen','Září','Říjen','Listopad','Prosinec',
]
