import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import CheckoutBasket from "./CheckoutBasket";
import InputPhone from "../UI/inupPhoneNumber/InputPhone";
import {Context} from "../../index";
import {updateUser} from "../../http/userAPI";
import {sendEmail} from "../../http/emailAPI";
import styles from "./CheckoutForm.module.css";
import emailBodyToHtml from "./emailBodyToHtml";
import {reserveBasket} from "../../http/basketAPI";
import {useNavigate} from "react-router-dom";

const CheckoutForm = () => {
    const {basketStore} = useContext(Context)
    const {user} = useContext(Context)
    const navigate = useNavigate()

    const [surname, setSurname] = useState(user.user?.surname || '')
    const [name, setName] = useState(user.user?.name || '')
    const [email, setEmail] = useState(user.user?.email || '')
    const [phone, setPhone] = useState(user.user?.phone || '')
    const [emailError, setEmailError] = useState(false)
    const [loadingBasket, setLoadingBasket] = useState(false)
    //const [showModalTest, setShowModalTest] = useState(false)
    const nameMexLength = 31


    const checkName = () => {
        return true
    }


    const checkEmail = (v) => {
        const mailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        return v.match(mailRegExp)
    }

    const setSurname1 = (v) => {
        v = v.slice(0, nameMexLength)
        checkName(v) && setSurname(v)
    }
    const setName1 = (v) => {
        v = v.slice(0, nameMexLength)
        checkName(v) && setName(v)
    }

    const onChangeEmail = (v) => {
        if (checkEmail(v)) setEmailError(false)
        else setEmailError(true)
        setEmail(v)
    }
    const onBlurEmail = (v) => {
        if (checkEmail(v)) setEmailError(false)
        else setEmailError(true)
    }

    const checkoutClickedHandler = () => { // Do reservation
        const basket = basketStore.basket
        const formData = new FormData()
        formData.append('name', name)
        formData.append('surname', surname)
        formData.append('email', email)
        formData.append('phone', phone)

        setLoadingBasket(true)
        updateUser(formData)
            .then((data) => {
                user.setUser(data)
                basket.user_id = data.id
            })
            .then(() => {
                return reserveBasket(basket.user_id)
            })
            .then(() => {
                const html = emailBodyToHtml(basket, user.user);
                basketStore.setBasket({user_id: basket.user_id, basket_products: []})
                const fd = new FormData()
                fd.append("html", html)
                //setShowModalTest(true) // test
                sendEmail(fd).catch(() => console.log("e-mail not sent"))
            })
            .then(() => {
                //console.log("Letter has sent successfully")
                navigate('/thanks')
            })
            .catch(e => {
                    alert(`Reservation error: ${e.message}`)
                }
            )
            .finally(() => setLoadingBasket(false))
    }

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'instant'});
    },[])

    return (
        <div className={styles.cont}>
            <Row>
                <Col sm={12} md={8}>
                    <Container className={`px-md-0 ${styles.title}`}>Kontaktní informace
                    </Container>
                    <Container fluid className={`px-md-0 ${styles.cont1}`}>
                        <div className={styles.textCont}>
                            <div className={styles.text}>Příjmení</div>
                            <input className={styles.input} value={surname}
                                   onChange={e => setSurname1(e.target.value)}/>

                            <div className={styles.text}>Jméno</div>
                            <input className={styles.input} value={name} onChange={e => setName1(e.target.value)}/>

                            <div className={styles.text}>E-mail</div>
                            <input className={emailError ? `${styles.input} ${styles.error}` : `${styles.input}`}
                                   value={email}
                                   type="email"
                                   onChange={e => onChangeEmail(e.target.value)}
                                   onBlur={e => onBlurEmail(e.target.value)}
                            />
                            {emailError ?
                                <div className={styles.emailErrTxt}>Zkontrolujte správnost e-mailové adresy</div> :
                                <div className={styles.emailErrTxt}></div>
                            }

                            <div className={styles.text}>Mobil</div>

                            <InputPhone phone={phone} setPhone={setPhone}/>

                        </div>
                    </Container>
                    <Container fluid className="px-md-0 mb-3 mb-md-0">
                        <div className={styles.text2}>
                            Dokončením rezervace potvrzuji, že jsem se seznámil/a
                            se Všeobecnými obchodními podmínkami, porozuměl/a jsem jejich obsahu a souhlasím s nimi.
                        </div>
                        <div className={styles.text3}>
                            Informace o <span>zpracování osobních údajů.</span>
                        </div>

                        <div className={styles.btns}>
                            <div className={styles.goBack}
                                 onClick={() => navigate('/')}
                            >
                                Zpět k vyběru
                            </div>

                            {loadingBasket ?
                                <div className={styles.gotoButton}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"/>
                                </div>
                                :
                                <div className={styles.gotoButton} onClick={checkoutClickedHandler}>
                                    Reservovat
                                </div>
                            }

                        </div>
                    </Container>
                </Col>
                <Col sm={12} md={4} className="px-md-0">
                    <Container fluid className={`pe-md-3 ${styles.cont2}`}>
                        <CheckoutBasket productClickHandler={() => {
                        }}/>
                    </Container>

                    <Container fluid className={styles.btns_sm}>
                        <div className={styles.goBack}
                             onClick={() => navigate('/')}
                        >
                            Zpět k vyběru
                        </div>

                        {loadingBasket ?
                            <div className={styles.gotoButton}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"/>
                            </div>
                            :
                            <div className={styles.gotoButton} onClick={checkoutClickedHandler}>
                                Reservovat
                            </div>
                        }
                    </Container>
                </Col>
            </Row>
        </div>
    )
};

export default CheckoutForm;