import React, {useContext} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {dateToDDMMYY} from "../../utils/dateTime";
import styles from './css/Basket.module.css'
import InputAmount from "../UI/InputAmount";
import del from "../reservationComponents/img/icon-delete-cart.svg";
import {myArray} from "../../utils/myArray";
import {Image} from "react-bootstrap";

const BasketProducts = observer(({clickedPlusMinusButton}) => {
    const {basketStore} = useContext(Context)
    const basket = basketStore.basket
    const c = new myArray(...basket.basket_products)
        .groupBy4('product_id', 'variant_id', 'additive_id', 'type_id')

    const bpGroupedBy = Object.values(c)?.map(xs => ({
        ...xs.at(0),
        number: xs.reduce((sum, x) => sum + x.amount, 0), fullPrice: xs.reduce((sum, x) => sum + x.price * x.amount, 0),
        orderDates: xs.map(x => ({id: x.id, order_date: x.order_date, amount: x.amount, price: x.price}))
    }))


    //console.log("bpGroupedBy >>" + JSON.stringify(bpGroupedBy, null, 2))
    return (
        <div>
            {bpGroupedBy
                .map((bp) =>
                    <div key={bp.id}>
                        <div className={styles.basketRect}>
                            <Image className={styles.basketImg}
                                   src={process.env.REACT_APP_IMG_URL + bp.product?.img}
                                   alt=""
                            />
                            <div className={styles.textBlock}>
                                <div className={styles.page}>

                                    <div className="d-flex justify-content-between">
                                        <div style={{width: 134, paddingTop: 3}}>
                                            <span>{bp.product?.name}</span>
                                            <div/>
                                            <span>{bp.variant?.weight}g </span>
                                            <span>{bp.type?.name} </span>
                                            <span>{bp.additive?.name} </span>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            width: 50,
                                            fontSize: 'var(--bakery-fs6)',
                                            whiteSpace: 'nowrap'
                                        }}>{bp.fullPrice} Kč
                                        </div>
                                    </div>
                                    <table style={{width: '100%', marginTop: 10}}>
                                        <tbody>
                                        {bp.orderDates
                                            .sort((a,b)=>new Date(a.order_date)-new Date(b.order_date))
                                            .map(od =>
                                            <tr key={od.id}>
                                                <td width="40%" align="left" style={{color: "var(--secondary)"}}>
                                                    {dateToDDMMYY(od.order_date)}</td>
                                                <td width="10%" align="right">{od.amount}</td>
                                                <td width="15%" align="right">
                                                    <InputAmount amount={od.amount}
                                                                 pId={bp.production_id}
                                                                 setAmount={(a) => clickedPlusMinusButton(od.id, a)}
                                                    />
                                                </td>
                                                <td width="20%" align="right">{od.price * od.amount},-</td>
                                                <td width="15%" align="right">
                                                    <img alt='delete' src={del} style={{cursor: 'pointer'}}
                                                         onClick={() => clickedPlusMinusButton(bp.id, 0)}/>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={styles.shadow}/>
                    </div>
                )}
        </div>
    );
});

export default BasketProducts;
