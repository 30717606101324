import React, {useContext} from 'react';
import styles from "./css/ProductView.module.css"
import defaultPic from "../img/default-pic.svg"
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import ToBasketButton1 from "./ToBasketButton1";
import {blueButtonProds} from "./selectorArrays";

const ProductView = observer(({product, productions, putToBasket, ...props}) => {
    const {basketStore} = useContext(Context)

    let production = productions?.[0] || {}
    let amount = productions?.reduce((sum, p) => sum + (p.amountR || 0), 0) || 0
    let amountL = amount
    let productPrice = productions?.reduce((sum, p) => sum + p.price * (p.amountR || 0), 0) || 0

    if (basketStore.showBlueButton) {
        const {price, amountR} = blueButtonProds(basketStore.basket.basket_products, basketStore.blueButtonBuff) || {}
        production = {
            ...basketStore.blueButtonBuff,
            weightSelected: true,
            type_idSelected: true,
            additive_idSelected: true
        }
        productPrice = price
        amount = amountR
        amountL = amountR
    }

    const weight = production?.weightSelected ? `${production.weight} g` : ""
    const type = production?.type_idSelected ? production.type?.name || "" : ""
    const additive = production?.additive_idSelected ? production.additive?.name || "" : ""
    const price = production?.price?.toString() || ""
    return (
        <div className={styles.card}>
            <div className={styles.fixedCard}>
                <div className={`${styles.imgContainer}`}>
                    <img className={styles.img}
                         src={product?.img ? process.env.REACT_APP_IMG_URL + product.img : defaultPic}
                         alt={product?.name}/>
                </div>

                <div className="d-flex justify-content-center">
                    <div className={styles.body}>
                        {[
                            {name: "Hmotnost: ", v: weight},
                            {name: "Ve tvaru: ", v: type},
                            {name: "Posyp: ", v: additive},
                            {name: "Cena: ", v: price+" Kč"},
                            {name: "Množství: ", v: amountL + " ks"},
                        ].map(e =>
                            <div key={e.name} className="mt-2 px-2">
                                <div className="text-nowrap">
                                    {e.name} {e.v}
                                </div>

                            </div>
                        )}

                        <div className={styles.price}>
                            <div className="d-flex text-nowrap pb-1 align-items-end">
                                Celková cena:
                            </div>
                            <div className=" fs-3 pe-3 text-nowrap">
                                {productPrice.toString() + " Kč"}
                            </div>
                        </div>
                    </div>
                </div>

                <ToBasketButton1 amount={amount}
                                 putToBasket={putToBasket}
                                 {...props}
                />
            </div>
        </div>
    );
});

export default ProductView;