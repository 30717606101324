import React, {useEffect, useState} from 'react';
import CountrySelect from "./CountrySelect";
import styles from "../../reservationComponents/CheckoutForm.module.css";
import {getCountryCallingCode, isPossiblePhoneNumber} from "react-phone-number-input";
import {parsePhoneNumber} from "react-phone-number-input";
import icon_list from "../../../img/icon-list.png";

const InputPhone = ({phone, setPhone}) => {

    const COUNTRY = "CZ"

    const [country, setCountry] = useState(COUNTRY)
    const [localPhoneNumber, setLocalPhoneNumber] = useState("")
    const [phoneError, setPhoneError] = useState(false)
    const [isInternationalCode, setIsInternationalCode] = useState(false)
    const internationalCodeExp = /^[+]?((\s+)?(\d+)?(\s+)?)+$/
    const phoneRegExp = /^((\s+)?(\d+)?(\s+)?)+$/

    const maxPhoneNumberLength = 21

    useEffect(() => {
      /*  let phoneNumber = undefined

        try {
            */
        let phoneNumber = parsePhoneNumber(phone||"")
       /* } catch (e) { phoneNumber = undefined}
*/
        setCountry(phoneNumber?.country || COUNTRY);
        if (!isInternationalCode)
            setLocalPhoneNumber(formatLocalNumber(phoneNumber?.nationalNumber || ""))
        phone && setPhoneError(!isPossiblePhoneNumber(phone))
        // eslint-disable-next-line
        }, [phone])

    const formatLocalNumber = (p) => { // 3232 424 45 -> 323 242 445
        if (p?.length < 2) return p
        const
            pp = (p.match(/\d/g) || []).join(''),
            l = pp.length % 3
        return `${(pp.match(/\d{3}/g) || []).join(" ")}${l ? (" " + pp.slice(-l)) : ""}`
    }

    const joinPhoneNumber = (phone1, country1) => {
        phone1 = phone1.match(/\d/g)?.join('') || ""// take only digits
        return `+${getCountryCallingCode(country1)}${phone1}` // +420111222333
    }

    const isPossibleLocalPhoneNumber = (phone1) => {
        return isPossiblePhoneNumber(joinPhoneNumber(phone1, country))
    };

    const onChangeHandler = (p) => {
        if (isInternationalCode) {

            setPhoneError(false)
            p.match(internationalCodeExp) && setLocalPhoneNumber(p)

        } else if (p.length < localPhoneNumber.length) {
            setLocalPhoneNumber(p)

        } else if (p.length < maxPhoneNumberLength && p.match(phoneRegExp)) {
            setLocalPhoneNumber(formatLocalNumber(p))
            setPhoneError(!isPossibleLocalPhoneNumber(p))
        } else {
            setPhoneError(true)
        }
    };

    const onBlur = () => {
        if (isInternationalCode) {
            setPhone(localPhoneNumber)
            isPossiblePhoneNumber(localPhoneNumber) || setPhoneError(true)

        } else if (isPossibleLocalPhoneNumber(localPhoneNumber)) {
            setPhoneError(false)
            setLocalPhoneNumber(formatLocalNumber(localPhoneNumber))
            setPhone(joinPhoneNumber(localPhoneNumber, country))
        } else {
            setPhone(joinPhoneNumber(localPhoneNumber, country))
            setPhoneError(true)
        }
    }

    const setCountry1 = (v) => {
        setIsInternationalCode(false)
        setCountry(v)
    }

    return (
        <div>
            <div className="d-flex justify-content-between ">
                {isInternationalCode ?
                    <div className={styles.inputCountry}
                         onClick={() => setIsInternationalCode(false)}
                    >
                        <span className={styles.anotherCountry}>Jiná země</span>
                        <img src={icon_list} alt={">"}/>
                    </div>
                    :
                    <CountrySelect value={country} onChange={setCountry1}
                                   setIsInternationalCode={setIsInternationalCode}/>
                }
                <input className={phoneError ? `${styles.inputPhone} ${styles.error}` : `${styles.inputPhone}`}
                       value={localPhoneNumber}
                       onChange={e => onChangeHandler(e.target.value || "")}
                       onBlur={e => onBlur(e.target.value)}
                />
            </div>
            {phoneError ?
                <div className={styles.phoneErrTxt}>Neplatný formát telefonního čísla</div> :
                <div className={styles.phoneErrTxt}></div>
            }
        </div>
    );
};

export default InputPhone;