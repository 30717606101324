import {distinct} from "../../../utils/myArray";
import {productionStore} from "../../../index";

/*export const weightArray = (productions) =>
    Array.from(new Set(productions.map(p => p.weight)
        .sort((a, b) => a - b)));

export const shapeArray = (productions, selected) =>
    Array.from(new Set(productions.filter(f =>
        f.weight === selected.weight).map(p => p.type_id)
        .sort((a, b) => a - b)));


export const additiveArray = (productions, selected) => {
    let aaa =
        Array.from(new Set(productions.filter(f =>
            f.type_id === selected.type_id &&
            f.weight === selected.weight)
            .map(p => p.additive_id)
            .sort((a, b) => a - b)));
    return aaa
}
*/

export const getPossibleProductions = (productions, selected) => {
    if (!productions?.length || !selected) return [];

    return distinct(
        productions.filter(f =>
            f.type_id === selected.type_id &&
            f.additive_id === selected.additive_id &&
            f.weight === selected.weight),
        "planned_date"
    )
        .map(p => ({...p, weightSelected: true, type_idSelected: true, additive_idSelected: true}))
        .sort((a, b) => new Date(a.planned_date) - new Date(b.planned_date))
}

export const sortProductions = (productions) => {
    if (!productions || productions?.length < 2) return productions
    return productions
        .sort((a, b) => new Date(a.planned_date) - new Date(b.planned_date))
        .sort((a, b) => a.additive_id - b.additive_id)
        .sort((a, b) => a.type_id - b.type_id)
        .sort((a, b) => a.weight - b.weight)
}

export const distinctProductions = (productions) => {
    return [sortProductions(productions)?.at(0) || {}]
}

export const defaultProductions = (product, productions = productionStore.productions) => {
    return  distinctProductions(productions.filter(p => p.product_id === product?.id));
};

export const defaultAllProductions = (products) => {
    return   distinctProductions(productionStore.productions.filter(p => p.product_id === products?.at(0).id));
}


/**
 *
 * @param bps basket_products
 * @param sp selectedProduction
 * @returns {*&{amountR: (*|number), price: (*|number), isClickedToBasketButton: boolean}}
 */

export const blueButtonProds = (bps, sp) => {
    const filteredBps = bps?.filter(bp => sp?.type_id === bp.type_id && sp?.weight === bp.variant?.weight);
    const amountR = filteredBps?.reduce((sum, bp) => sum + (bp.amount || 0), 0) || 0;
    const price = filteredBps?.reduce((sum, bp) => sum + (bp.amount * bp.price || 0), 0) || 0;

    return {
        ...sp,
        amountR,
        price,
        isClickedToBasketButton: true
    }
}


