import {fetchOneBasket, reserveBasket, updateBasket} from "../../../http/basketAPI";
import {useEffect} from "react";

export async function addToBasket(basket) {

    const formData = new FormData()
    formData.append('user_id', basket.user_id)
    formData.append('basketProducts', JSON.stringify(basket.basket_products))
    return await updateBasket(formData)
}

export async function reserve(basket) {
    return await reserveBasket(basket.user_id)
}


export const useLoadUserBasket = (basketStore, setLoading) => {
    useEffect(() => {
        fetchOneBasket(basketStore.basket.user_id)
            .then(data => {
                data && basketStore.setBasket(data)
                //console.log("loadUsersBasket" + JSON.stringify(data, null, 2))
            })
            .catch(e => alert(`ups...${e}`))
            .finally(() => setLoading ? setLoading(false) : {})
        // eslint-disable-next-line
        }, [])
}

export const areAllInstancesSelected = (shape, weight, additive) => {
    if (!weight.id) {
        return [false,"Vyberte hmotnost"]
    }
    if (!shape.id) {
        return [false,"Zvolit tvar"]
    }
    if (!additive.id) {
        return [false,"Vyberte posyp"]
    }
    return [true,'']
}
