import React, {useContext, useRef, useState} from 'react';
import Calendar from "../../calendarComponets/Calendar";
import {getPossibleProductions} from "./selectorArrays";
import DateSelector2 from "./DateSelector2";
import DateSelector1 from "./DateSelector1";
import MyModal from "../../myModalComponents/MyModal";
import {useScrollControl} from "../../myModalComponents/hooks/useScrollControl";
import {dateCompare} from "../../../utils/dateTime";
import {getCSSVariableInt} from "../../../utils/getCSSVariable";
import {Context} from "../../../index";
import {isTimeUntilClosing} from "../../../utils/howLongUntileClosing";


const DateSelector = ({productions, selectedProductions, setSelectedProductions}) => {
    const calendarRef = useRef(null)
    const [showCalendar, setShowCalendar] = useState(false)
    const {productStore} = useContext(Context)


    const [sps, setSps] = useState([])

    let scrollY = window.scrollY
    let calendarScrollHeight = (selectedProductions?.length || 0) * 92 + 50

    const isSelectCompleted = Boolean(selectedProductions?.find(p=>
        p.weightSelected &&
        p.type_idSelected &&
        p.additive_idSelected)
    )

    const setDate = () => {
        if (isSelectCompleted) {
            scrollY = window.scrollY
            sps?.length && setSps(selectedProductions)
            setShowCalendar(true)
        }
    }

    const setTimeInterval = (production, issueInterval) => {

        setSelectedProductions(selectedProductions.map(p => p.id === production.id ? {
            ...p,
            issueInterval
        } : p))
    }

    const setAmount = (p, v) => {
        setSelectedProductions(selectedProductions.map(s => s.id === p.id ? {
            ...s,
            amountR: (v === "-") ? (s.amountR > 0 ? s.amountR - 1 : 0) : (s.amountR || 0) + 1
        } : s))
    }

    const calendarCancel = () => {
        //setSps(selectedProductions)
        setShowCalendar(false)
    }
    const okCalendar = () => {
        setSelectedProductions(sps?.length ? sps : selectedProductions.map(p => ({...p,
            selectedDate: undefined, issueInterval: undefined, amountR: 0})))
        setShowCalendar(false)
    }


    const calendarModalXY = {
        height: 325,
        heightBody: 325,
        width: 'auto',
        top: (calendarRef?.current?.getBoundingClientRect().top || 0),
        left: (calendarRef?.current?.getBoundingClientRect().left || 0) + 52,
        containerHeight: calendarScrollHeight
    }
    let qqq = window.innerHeight - calendarModalXY.height - calendarModalXY.top
    if (calendarModalXY.top > getCSSVariableInt("--header-h")) {
        calendarModalXY.top = qqq > 0 ? calendarModalXY.top : calendarModalXY.top + qqq - 50
    } else {
        calendarModalXY.top = getCSSVariableInt("--header-h")
    }
    /*+*/
    useScrollControl(calendarScrollHeight, scrollY, () => {
        if (calendarScrollHeight && (
            window.scrollY - scrollY > calendarScrollHeight || scrollY - window.scrollY > 600
        )) {
            calendarCancel()
        }
    })
    /*-*/
    const spSelectedDates = selectedProductions.filter(sp => Boolean(sp.selectedDate))
    const DateSelectorN = (spSelectedDates?.length || 0) < 2 ? DateSelector1 : DateSelector2;

    const possibleProductions = getPossibleProductions(productions.filter(p=>p.amount), selectedProductions?.at(0))
        ?.filter(p=> isTimeUntilClosing(productStore.products,p))

    return (
        <div ref={calendarRef}>
            <div className="d-flex">
                <MyModal modalXY={calendarModalXY}
                         isOpenModal={showCalendar}
                         closeModal={calendarCancel}
                         containerHeight={calendarScrollHeight}
                         isMouseLeaveCloseModal={false}
                >
                    <Calendar possibleProductions={possibleProductions}
                              closeHandler={calendarCancel}
                              okHandler={okCalendar}
                              sps={sps}
                              setSps={setSps}
                    />

                </MyModal>
            </div>
            {spSelectedDates?.length ?
                spSelectedDates
                    .sort((a, b) => new Date(a.planned_date) - new Date(b.planned_date))
                    .map((p, index) =>
                        <div key={p.id} className="">
                            <DateSelectorN index={index}
                                           production={p}
                                           onHereDateClicked={setDate}
                                           selectedProductions={selectedProductions}
                                           setAmountHandler={setAmount}
                                           setTimeHandler={setTimeInterval}
                            />
                        </div>
                    )
                :
                <DateSelector1
                    production={selectedProductions
                        ?.sort((a, b) => dateCompare(a.planned_date, b.planned_date))
                        ?.at(0)
                    }
                    onHereDateClicked={setDate}
                    selectedProductions={selectedProductions}
                    setAmountHandler={setAmount}
                    setTimeHandler={setTimeInterval}
                    setSelectedProductions={setSelectedProductions}
                    isSelectCompleted={isSelectCompleted}
                />
            }
        </div>
    );
};

export default DateSelector;

