import React, {useContext, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Context, productionStore} from "../../../index";
import styles from "./ShopProducts.module.css"
import defaultPic from "../img/default-pic.svg"

const ShopProducts = ({goToReservation}) => {
    const {productStore} = useContext(Context)

    let products = Object.assign([], productStore.products)
    if (window.innerWidth > 768) {
        const rest = products.length % 3 // for 3 columns
        for (let i = 0; rest !== 0 && i < 3 - rest; i++) {
            products.push({isEmpty: true, id: new Date() + i})
        }
    }

    const styleStr = (index) => {
        // Deletes paddings and margins from first and last item in row
        if (window.innerWidth > 768) {
            if (index % 3 === 0) return "d-flex justify-content-center ms-0 ps-0"
            if ((index + 1) % 3 === 0) return "d-flex justify-content-center me-0 pe-0"
            if ((index + 2) % 3 === 0) return "d-flex justify-content-center pe-0 ps-0"
            return "d-flex justify-content-center"
        } else {
            return "d-flex justify-content-center"
        }
    }

    const [animationId, setAnimationId] = useState(null)
    const setAnimation = (id) => {
        setAnimationId(id)
        //setTimeout(() => setAnimationId(null), 1000)
    }

    return (

        <Container fluid className="px-0">
            <div className="shopSectionName">
                Sortiment
            </div>
            <Row className="mx-0">
                {products.map((product, index) =>
                    <Col key={product.id} sm={6} md={4} className={styleStr(index)}>
                        <Container fluid className={animationId === product.id && !product.isEmpty  ? styles.cardAnimation : styles.card}
                                   onMouseEnter={() => setAnimation(product.id)}
                                   onMouseLeave={() => setAnimationId(null)}
                        >
                            <div className={styles.imgW}>
                                <img className={styles.img}
                                     src={product?.img ? process.env.REACT_APP_IMG_URL + product.img : defaultPic}
                                     alt={product.name}

                                >
                                </img>
                            </div>
                            {!product.isEmpty ?
                                <div>
                                    <div className={styles.title}>
                                        {product.name}
                                    </div>
                                    <div className={styles.info}>
                                        <div className={styles.body}>
                                            <div style={{marginBottom: 4}}>Složení: {product.composition}</div>
                                            <div style={{marginBottom: 4}}> {product.description}</div>
                                            <div className={styles.attributes}>
                                                {product.variants.length && product.reserve?
                                                    <div style={{marginBottom: 4}}>
                                                        Hmotnost: {product.variants?.map((v, i) =>
                                                        <span
                                                            key={v.id}>{v.weight}g{i < product.variants.length - 1 ? ',' : ''}&nbsp;
                                                        </span>
                                                    )}
                                                    </div>
                                                    :
                                                    <div/>
                                                }
                                                {/*<div>Minimální trvanlivost: {product.shelf_life}</div>*/}
                                                <div>{product.allergens?.map((a, i) =>
                                                    <div
                                                        key={a.id}>{a.name}{i !== product.allergens?.length - 1 ? ', ' : '.'}
                                                    </div>
                                                )}</div>
                                                <div style={{marginBottom: 4}}> {product.comment}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.buttonArea}>
                                        {product.reserve && productionStore.productions.find(p => p.product_id === product.id) ?
                                            <div className={styles.buttonReserve}
                                                 onClick={() => goToReservation(product)}
                                            >
                                                Objednat >
                                            </div>
                                            :
                                            <div className={styles.inTheShope}>
                                                Na prodejně
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div></div>
                            }
                        </Container>
                    </Col>
                )}
            </Row>

        </Container>
    )
};
export default ShopProducts;