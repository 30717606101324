import React, {useContext, useState} from 'react';
import {Context} from "./index";
import Shop from "./pages/Shop";
import {Spinner} from "react-bootstrap";
import {useShadowLogin} from "./http/userAPI";
import {BrowserRouter} from "react-router-dom";
import NotConnectedToApi from "./components/notConnectedComponents/notConnectedToAPI";

const App = () => {
    const {user} = useContext(Context)
    const {basketStore} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [isConnected, setIsConnected] = useState(false)
    useShadowLogin(user, basketStore, setLoading, setIsConnected)

    if (loading) {
        return (<Spinner animation={"grow"}/>)
    } else if (!isConnected) {
        return (<NotConnectedToApi/>)
    }

    return (
        <BrowserRouter>
            <Shop/>
        </BrowserRouter>
    );
};

export default App;
