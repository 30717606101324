import React, {useContext} from 'react';
import styles from "./css/ProductView.module.css";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {Container, Spinner} from "react-bootstrap";

const ToBasketButton1 = observer(({amount, putToBasket, updating}) => {
    const {basketStore} = useContext(Context)
    return (
        <Container fluid style={{paddingBottom: 40}}>
            {basketStore.showBlueButton ?
                <div className={styles.buttonBlue}>
                    V košiku máte {amount} ks
                    {/*K objednávce*/}
                </div>
                :
                <div>
                    {amount === 0 ?
                        <div className={styles.buttonGray}>
                            Do košiku
                        </div>
                        :
                        <>
                        {updating?
                                <div className={styles.buttonRed}>
                                    <Spinner animation="border" size="sm"/>
                                </div>
                                :
                                <div className={styles.buttonRed}
                                     style={{backgroundColor: "var(--warning)"}}
                                     onClick={putToBasket}>
                                    Do košiku
                                </div>
                        }
                        </>
                    }
                </div>
            }
        </Container>
    );
});

export default ToBasketButton1;