import React from 'react';
import {Carousel} from "react-bootstrap";
import hdr1 from "./img/Header1.jpg"
import hdr2 from "./img/Header2.jpg"
import hdr3 from "./img/Header3.jpg"
import hdr4 from "./img/Header4.jpg"
import hdr5 from "./img/Header5.jpg"
import hdr6 from "./img/Header6.jpg"

const HdrCarousel = ({slideChanged,interval}) => {

    return (
        <Carousel interval={interval || 5000} onSelect={slideChanged}>
            {[hdr1, hdr2, hdr3, hdr4, hdr5, hdr6].map(hdr =>
                <Carousel.Item key={hdr}>
                        <img src={hdr}
                             className="myCarouselImg"
                            alt="Slide"
                        />
                </Carousel.Item>
            )}
        </Carousel>
    );
};

export default HdrCarousel;