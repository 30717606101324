import React, {useContext, useEffect, useRef} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import styles from "./NavBar.module.css"
import logo from "./img/logo.svg";
import basketPng from "./img/icon-cart-empty.svg"
import basketPngFull from "./img/icon-cart-full.svg"
import {Container} from "react-bootstrap";

const NavBar = observer(({navBarItem, navBarItemClicked,
                             navBarMenu, logoX, setLogoX}) => {
    const {basketStore} = useContext(Context)
    const logoRef = useRef(null);
    const {productStore} = useContext(Context)

    const navBarItemClickedIn = (item) => {
        navBarItemClicked(item)
    }

    useEffect(() => {
        setLogoX(logoRef?.current.getBoundingClientRect().left)
        // eslint-disable-next-line
    }, [basketStore.basketXY.innerWidth])

    //console.log("NavBar>>>"+basketStore.showBasketModal)
    /*+ --Modal-- +*/
    const basketRef = useRef(null);

    useEffect(() => {
        const el = basketRef?.current
        basketStore.setBasketXY(el ? {
            left: el.getBoundingClientRect().left - 285, // 285 = 310 - 25 basket placement
            top: el.getBoundingClientRect().top + 38,
            height: "toBottom",
            weight: 310,
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            scrollY: window.scrollY
        } : {})
        // eslint-disable-next-line
    }, [basketStore.basketXY.innerWidth, basketStore.basketXY.innerHeight])

    useEffect(() => {
        const onResize = () =>
            basketStore.setBasketXYWindow({
                w: window.innerWidth,
                h: window.innerHeight
            });

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize);
        }
        // eslint-disable-next-line
    }, []);

    /*- --**-- -*/

    const bpAmount = basketStore.basket.basket_products?.reduce((sum, bp) => sum + bp.amount, 0) || 0;

    useEffect(() => {
        bpAmount || basketStore.setShowBasket(false)
        //console.log("NavBar basketStore.basketXY>>" + JSON.stringify(basketStore.basketXY,null,2))
        // eslint-disable-next-line
    }, [bpAmount])

    return (
        <Container fluid className={`px-0 ${styles.headerContainer}`}>
            <div className={styles.navBar}/> {/* background */}
            <div className={styles.navBarP}> {/* shadow */}

                <div className={styles.logoContainer}
                     style={{left: logoX}}
                >
                    <img src={logo} alt="logo" className={styles.logo}
                         onClick={() => navBarItemClickedIn(0)}
                    />
                </div>

                <div ref={logoRef} className={styles.header}>
                    <div className={styles.navbarMenu}>
                        {navBarMenu.map(n =>
                            <div
                                key={n.name}
                                className={styles.menuItem}
                                style={{
                                    color: n.id === 3 ? 'var(--warning)' : 'var(--text)',
                                    fontWeight: n.id === ~~navBarItem ? '800' : 'normal'
                                }}
                                onClick={() => navBarItemClickedIn(n.id)}
                            >{n.name}
                            </div>
                        )}
                    </div>

                    {productStore.products?.length > 0 ?
                        <div className={styles.basket}>
                        {bpAmount > 0 ?
                            <div
                                className={styles.amount}
                                onMouseOver={() => basketStore.setShowBasket(true)}
                                onClick={() => basketStore.setShowBasket(true)}
                            >
                                {bpAmount} ks
                            </div>
                            :
                            <div/>
                        }
                            <div ref={basketRef} className={styles.cart}>
                                <img src={bpAmount > 0 ? basketPngFull : basketPng} alt="Basket Cart"
                                     onMouseOver={() => {
                                         bpAmount > 0 && basketStore.setShowBasket(true)
                                     }}
                                 onClick={() => {
                                     bpAmount > 0 && basketStore.setShowBasket(true)
                                 }}
                                />
                            </div>
                        </div>
                        :
                        <div ref={basketRef}></div>
                    }
                </div>
            </div>
        </Container>
    );
});

export default NavBar;
