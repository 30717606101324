import React, {useState} from 'react';
import {ButtonGroup, Spinner} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {MyDropdownToggle} from "./MyDropdownToggle";
import {observer} from "mobx-react-lite";
import {getProductionAmount} from "../../http/productionAPI";
import qnt from "../reservationComponents/img/icon-qnt.svg";


const InputAmount = observer(({amount, setAmount, pId}) => {

    const
        maxAmount = parseInt(process.env.REACT_APP_MAX_NUMBER_IN_ONE_HAND) || 5,
        defaults = Array.from(Array(maxAmount), (_, index) => index + 1),
        [ns, setNs] = useState([]),
        [loading, setLoading] = useState(false)

    const getLeftover = () => {
        if (pId) {
            setLoading(true)
            getProductionAmount(pId)
                .then(l => { //leftover
                    const maxPossible = (amount+l < maxAmount) ? amount + l :  maxAmount


                    setNs(Array.from(Array(maxPossible).keys(), n => n + 1))
                })
                .catch(e => console.log('error:' + e))
                .finally(() => setLoading(false))
        } else {
            setNs(defaults)
        }
    }

    return (
        <div>
            <Dropdown as={ButtonGroup} style={{width: 20}}>
                <Dropdown.Toggle as={MyDropdownToggle}>
                    <div className="d-flex align-content-center">
                        {loading ?
                            <Spinner animation="border" variant="primary" size="sm"/> :
                            <div onClick={getLeftover}>
                                <img src={qnt} style={{cursor: 'pointer'}} alt="v"/>
                            </div>}
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{minWidth: 30}}>
                    {ns.map(n =>
                        <Dropdown.Item key={n} onClick={() => {
                            setAmount(n)
                        }}>
                            {n}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>


            </Dropdown>
        </div>);
});

export default InputAmount;