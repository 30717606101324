import React, {useEffect, useState} from 'react';
import styles from "./css/ProductSelector.module.css";
import {dateToDDMMYY} from "../../../utils/dateTime";
import TimeSelector from "./TimeSelector";
import AmountBtn from "./AmountBtn";

const DateSelector2 = ({
                           production,
                           onHereDateClicked,
                           selectedProductions,
                           setTimeHandler,
                           setAmountHandler,
                           index,
                           ...props
                       }) => {

    const [timeStyle, setTimeStyle] = useState(`${styles.rect} ${styles.time}`)
    const [amountError, setAmountError] = useState('')
    const [errorColor, setErrorColor] = useState('var(--primary')


    let isIntervalSelected = Boolean(production?.issueInterval)
    const isSelectCompleted = Boolean(selectedProductions?.find(p =>
        p.weightSelected &&
        p.type_idSelected &&
        p.additive_idSelected)
    )

    useEffect(() => {
        isSelectCompleted ?
            production?.issueInterval ?
                setTimeStyle(`${styles.timeInterval2} ${styles.selected}`)
                :
                setTimeStyle(`${styles.timeInterval2} ${styles.possible}`)
            :
            setTimeStyle(`${styles.timeInterval2} ${styles.muted}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntervalSelected])

    const setAmountHandlerPlus = (p, v) => {
        p.issueInterval ?
            setAmountHandler(p, v)
            :
            setTimeStyle(`${styles.timeInterval2} ${styles.warning}`)
    }

    const dateClass = isSelectCompleted ?
        production?.selectedDate ? `${styles.propSelected}` : `${styles.propCanSelect}`
        :
        `${styles.propCanNotSelect}`

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div>{index + 1}. Datum, čas a množství</div>
                <div className={styles.error}>{amountError}</div>

            </div>
            <div className="d-block d-sm-flex justify-content-between  mt-0 px-0">
                <div className="d-flex w-100 justify-content-center">
                    {/* Date */}
                        <div className={styles.column}>
                            <div className={styles.selectorS}>
                                <div className={dateClass}
                                     onClick={() => onHereDateClicked(production)}
                                >
                                    {dateToDDMMYY(production.planned_date)}
                                </div>
                            </div>
                        </div>

                        {/* Time */}
                        <div className={`${styles.column}`}>
                            <div className={styles.selectorS}>
                                <TimeSelector setAmountHandler={setAmountHandler}
                                              production={production}
                                              selectedProductions={selectedProductions}
                                              setTimeHandler={setTimeHandler}
                                              timeStyle={timeStyle}
                                              width={126}
                                              isSelectCompleted={isSelectCompleted}
                                              {...props}
                                />
                            </div>
                        </div>
                </div>
                {/* Amount */}
                <div className={styles.columnAmount}>
                    <AmountBtn production={production}
                               selectedProductions={selectedProductions}
                               setAmountHandler={setAmountHandlerPlus}
                               amountError={amountError} errorColor={errorColor}
                               setAmountError={setAmountError}
                               setErrorColor={setErrorColor}
                               setTimeStyleWarning={setTimeStyle}
                               timeStyles={{timeStyleWarning: `${styles.timeInterval2} ${styles.warning}`, timeStyleNormal: `${styles.timeInterval2} ${styles.possible}`}}
                    />
                </div>
            </div>

        </div>
    );
};

export default DateSelector2;