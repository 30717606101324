import React from 'react';

/*
https://www.googlemapsiframegenerator.com/?gclid=CjwKCAiAx_GqBhBQEiwAlDNAZtZQ3dlB79Rpoi1FRQDqMz-oh4allFm2CzBA8YMC2LTzS5pDpVLUhBoClAUQAvD_BwE#!
*

src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.6140806686194!2d14.070573751359882!3d49.96234143019425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ba56b59db64d1%3A0x4eb929ac8dd6ef04!2sHolandsk%C3%A1%20122%2C%20Centrum%2C%20266%2001%20Beroun!5e0!3m2!1scs!2scz!4v1671615931651!5m2!1scs!2scz"
src="https://maps.google.com/maps?width=300&amp;height=252&amp;hl=en&amp;q=pekarna zivy chleb&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
*/
const GoogleMapHtml = ({width,height}) => {
    return (
        <div style={{color: 'fff', zIndex: 0}}>
            <div style={{overflow: "hidden"}}>
                <iframe style={{zIndex: 2,border: "none"}}
                        title={'map'}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.614265066769!2d14.070192876832476!3d49.962337971503125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ba51eb10878d1%3A0x66bae659999cb2da!2zUGVrw6FybmEgxb1pdsO9IENobMOpYg!5e0!3m2!1scs!2scz!4v1700646233754!5m2!1scs!2scz"
                        width={width} height={height}
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    );
};

export default GoogleMapHtml;