import {$authHost} from "./index";
import jwt_decode from "jwt-decode";
import {useEffect} from "react";

export const updateUser = async (values) => {
    try {
        const {data} = await $authHost.post('api/user/update/', values)
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    } catch (e) {
        //window.location.reload(); // We are here because token is broken or something like this
        //throw e
        throw new Error("Token is broken. Refresh the application.")
    }
}
const shadowLogin = async () => {
    const {data} = await $authHost.post('api/user/shadowLogin')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const useShadowLogin = (user, basketStore, setLoading, setIsConnected) => {
    //console.log(">>>"+process.env.REACT_APP_API_URL)
    useEffect(() => {
        shadowLogin()
            .then(data => {
                user.setUser(data)
                user.setIsAuth(true)
                user.setIsAdmin(false)
                basketStore.setBasket({...basketStore.basket, user_id: data.id})
                setIsConnected(true)
            })
            .catch(() => {
                //console.log("useShadowLogin error: " + e)
                //setIsConnected(false)
            })
            .finally(() => {
                setLoading(false)
            })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}