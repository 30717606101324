import {$host} from "./index";


export const getBasketActivityAPI = async (id) => {
    if(!id) return false
    const {data} = await $host.get('api/activity/basket/'+id)
    return data
}

export const getContentActivityAPI = async () => {
    const {data} = await $host.get('api/activity/content/')
    return data
}
