import {useEffect} from "react";

export const useScrollControl = (height,scrollY, callback) => {

    useEffect(() => {
        const handleScroll = () => {
                callback();
        };

        document.addEventListener('scroll', handleScroll, true);

        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };
        // eslint-disable-next-line
    }, [height,scrollY]);

};
