import {useEffect} from "react";
import {getBasketActivityAPI} from "../http/activityAPI";
import {fetchOneBasket} from "../http/basketAPI";
import {fetchProductionsAmounts} from "../http/productionAPI";
import {productionStore} from "../index";

export const loadProductionsAmounts = () => {
    if (productionStore.productions.length > 1) {
        const qqq = (pas, innerId) => pas?.find(pa => pa.id === innerId) || {}
        fetchProductionsAmounts()
            .then(pas => productionStore.setProductions(
                productionStore.productions.map(p => ({...p, amount: qqq(pas, p.id).amount, amountR: 0}))))
    }
}

export const loadUserBasket = (basketStore, setLoading) => {
    fetchOneBasket(basketStore.basket.user_id)
        .then(data => {
            if (!data) {
                data = {user_id: basketStore.basket.user_id, basket_products: []}
            }
            basketStore.setBasket(data)
            //console.log("loadUsersBasket" + JSON.stringify(data, null, 2))
        })
        .then(() => loadProductionsAmounts())
        .catch(e => alert(`ups...loadUserBasket said: ${e}`))
        .finally(() => setLoading ? setLoading(false) : {})
}

export const useCheckBasketActivity = (callBack, basketStore) => {

    //eslint-disable-next-line
    useEffect(() => callBack(), [])
    useEffect(() => {
        const i = setInterval(() => {
            if (basketStore.basket?.id) {
                getBasketActivityAPI(basketStore.basket.id).then(b => {
                    //console.log("activity>> " + b + '  bId: ' + basketStore.basket?.id)
                    b && callBack()
                }).catch(e => console.log("getBasketActivity error: " + e))
            }
        }, 1000 * 10) // 10 sec
        return () => clearInterval(i)
        //eslint-disable-next-line
    }, [])
}
