import React, {useContext} from 'react';
import styles from "./TechInfoCard.module.css";
import {Context} from "../../index";
import go from "./img/icon-go.svg"

const TechInfoCard = ({gotoQuality, indexToShowText}) => {

    const {productStore} = useContext(Context)
    const qualities = productStore.qualities
    const strLength = 190

    const text = qualities[indexToShowText].article.length > strLength ?
        qualities[indexToShowText].article.substring(0, strLength) + '...' :
        qualities[indexToShowText].article

    const {basketStore} = useContext(Context)
    return (
        <div>
            {
                qualities.length ?
                    <div className={styles.healthTechnology}
                         style={{left: `${basketStore.basketXY?.left - 22}px`}}>
                        <div className={styles.healthTechnologyTitle}>
                            {qualities[indexToShowText].name}
                        </div>
                        <div className={styles.healthTechnologyBody}>
                            {text}
                        </div>
                        <div className={styles.healthTechnologyButtonArea}>
                            <div className={styles.healthTechnologyButton} onClick={gotoQuality}>Zjistit více</div>
                            <img src={go} alt="go" className={styles.healthTechnologyGo} onClick={gotoQuality}/>
                        </div>
                    </div> : <div/>
            }
        </div>
    );
};

export default TechInfoCard;