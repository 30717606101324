import React, {useEffect, useRef} from 'react';
import styles from './myModal.module.css'
import {useOutsideClick} from "./hooks/useOutsideClick";


const MyModal = ({children, isOpenModal, closeModal, modalXY, arrowObj, isMouseLeaveCloseModal}) => {
    const modalRef = useRef(null);
    const isMouseLeaveClose = isMouseLeaveCloseModal === undefined ? true : isMouseLeaveCloseModal;

    const height = modalXY.height === "toBottom" ? modalXY.innerHeight - modalXY.top : modalXY.height || 310;
    const heightBody = modalXY.heightBody || height - 105
    const width = modalXY.width || 310

    useEffect(() => {
        isOpenModal ? open() : close()
        // eslint-disable-next-line
    }, [isOpenModal])

// When the user clicks the button, open the modal
    const open = () => {
        document.body.style.overflowY = "auto" /* To off scrolling change to 'hidden'*/
        modalRef.current.style.display = "block";
    }

// When the user clicks on <span> (x), close the modal
    /*span.onclick = function() {*/
    const close = () => {
        document.body.style.overflowY = "auto" /* To release scrolling*/
        modalRef.current.style.display = "none";
        closeModal()
    }

// When the user clicks anywhere outside the modal, close it
    /* window.onclick = function (event) {
         /!*console.log(event.target)
         console.log(modalRef.current)*!/
         if (!contentRef.current.contains(event.target)) {
             document.body.style.overflow = "auto" /!* To release scrolling*!/
             modalRef.current.style.display = "none";
             closeModal()
         }
     }*/

    const handleClickOutside = () => { /* When the user clicks anywhere outside the modal, close it */
        document.body.style.overflowY = "auto" /* To release scrolling*/
        modalRef.current.style.display = "none";
        closeModal()
    };

    const contentRef = useOutsideClick(handleClickOutside);

    const onMouseLeaveHandler = () => {
        isMouseLeaveClose && close()
    }


    /* --Arrow */
    const ArrowOnModal = ({
                              backgroundColor = "var(--white)",
                              offsetFromLeft = 258
                          }) => {
        return (
            <div style={{
                content: " ",
                position: "absolute",
                top: `${modalXY?.top - 8}px`,
                left: `${modalXY?.left + offsetFromLeft}px`,
                /*borderTop: "none",
                borderRight: "10px solid transparent",
                borderLeft: "10px solid transparent",
                borderBottom: `10px solid ${backgroundColor}`,*/
                width:15,
                height:15,
                backgroundColor: `${backgroundColor}`,
                transform: "rotate(45deg)",
                boxShadow: "0 -0.5px 6px 0 rgba(0, 0, 0, 0.1)"
            }}/>
        )
    }

    return (
        <div ref={modalRef} className={styles.modal}>
            {/*-- Modal content --*/}
            {/*<div ref={contentRef} className={`${styles.modalContent} ${styles.arrowTop}`}*/}
            {arrowObj?.isArrow ? <ArrowOnModal/> : <></>}
            {/*<div ref={contentRef} className={styles.modalContent}*/}
            <div ref={contentRef} className={styles.modalContent} onMouseLeave={onMouseLeaveHandler}
                 style={{
                     top: modalXY?.top,
                     left: modalXY?.left,
                     height: height,
                     width: width
                 }}
            >
                {/*<div>
                    <span className={styles.close} onClick={closeModal}>&times;</span>
                </div>*/}
                <div className={styles.modalBody}
                     style={{height: `${heightBody}px`,overflowY:arrowObj?"auto":"hidden"}}
                >
                    {children}

                </div>
            </div>
        </div>
    );
};


export default MyModal;