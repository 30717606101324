import {ButtonGroup} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {MyDropdownToggle} from "../MyDropdownToggle";
import {getCountries, getCountryCallingCode} from 'react-phone-number-input/input'
import styles from "../../reservationComponents/CheckoutForm.module.css";
import icon_list from "../../../img/icon-list.png";
import React from "react";
import countryNames from 'react-phone-number-input/locale/en'

const necessaryCodes = ["420","421", "49", "40", "48", "36", "33", "43", "386", "385"];
const necessaryCountries = getCountries().filter(c => necessaryCodes.find(p => p === getCountryCallingCode(c)))

const CountrySelect = ({value, onChange, setIsInternationalCode, }) => {

    return (
        <div>
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={MyDropdownToggle}
                >
                    <div className={styles.inputCountry}>
                                    <span>
                                        +{getCountryCallingCode(value)}
                                    </span>
                        <img src={icon_list} alt={">"}/>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {necessaryCountries.map((c) =>
                        <Dropdown.Item key={c}
                                       className={styles.dropDown}
                                       onClick={() => onChange(c)}
                        >
                            {countryNames[c]} +{getCountryCallingCode(c)}
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => {
                        /*onChange("ZZ")*/
                        setIsInternationalCode(true)
                    }}>
                        <div style={{color: "var(--secondary)"}}>Jiná země</div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )

};

export default CountrySelect;

/*<select
            {...rest}
            className={styles.inputCountry}
            value={`+${getCountryCallingCode(value)}`}
            onChange={event => onChange(event.target.value || undefined)}>
            <option value="">
                {countryNames['ZZ']}
            </option>
            {necessaryCountries.map((country) => (
                <option key={country} value={country}>
                    {countryNames[country]} +{getCountryCallingCode(country)}
                </option>
            ))}
        </select>
*/