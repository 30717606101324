import {makeAutoObservable} from "mobx";

export default class BasketStore {
    constructor() {
        this._defaultBasket = {user_id: 1, basket_products: []}; //TODO
        this._basket = this._defaultBasket;
        this._basketPrev = {};
        this._showBasket = false;
        this._basketXY = {top: 0, left: 0, innerWidth: 0, innerHeight: 0};
        this._blueButtonBuff = {};
        this._showBlueButton = false;

        makeAutoObservable(this)
    }

    setBasketXY(basketXY) {
        this._basketXY = basketXY
    }

    get basketXY() {
        return this._basketXY
    }

    setBasket(basket) {
        this._basketPrev = this._basket
        this._basket = basket
    }

    get basket() {
        return this._basket
    }

    setBasketPrev(basket) {
        this._basketPrev = basket
    }

    get basketPrev() {
        return this._basketPrev
    }

    get defaultBasket() {
        return this._defaultBasket
    }

    setShowBasket = (val) => {
        this._showBasket = val
    }

    get showBasket() {
        return this._showBasket
    }

    setBasketXYWindow(w) {
        if (Math.abs(w.w - this._basketXY.innerWidth) > 1) {
            this._basketXY.innerWidth = w.w
        }
        if (Math.abs(w.h - this._basketXY.innerHeight) > 1) {
            this._basketXY.innerHeight = w.h
        }
    }

    setBlueButtonBuff(val) {
        this._blueButtonBuff = val
    }

    get blueButtonBuff() {
        return this._blueButtonBuff
    }


    setShowBlueButton(val) {
        this._showBlueButton = val
    }

    get showBlueButton() {
        return this._showBlueButton
    }
}