import React, {useContext} from 'react';
import styles from "./css/ProductSelector.module.css";
import {distinct} from "../../../utils/myArray";
import {Context} from "../../../index";

const PropertySelector = ({propertyKey, productions, selectedProductions, setSelectedProductions}) => {
    const {productStore} = useContext(Context)
    const sp = selectedProductions?.at(0) || {}

    const style1 = (key, production) => {
        const value = production[key]
        let style = styles.rect

        switch (key) {
            case "weight":
                style = sp?.weight === value ?
                    styles.propSelected
                    :
                    styles.propCanSelect
                break;
            case "type_id":
                style = productions.find(p => p.weight === sp.weight && p.type_id === value) ?
                    sp.type_id === value ?
                        styles.propSelected
                        :
                        styles.propCanSelect
                    :
                    styles.propCanNotSelect

                break;
            case "additive_id":
                style = productions.find(p => p.weight === sp.weight &&
                    p.type_id === sp.type_id && p.additive_id === value) ? //
                    sp.additive_id === value ?
                        styles.propSelected
                        :
                        styles.propCanSelect
                    :
                    styles.propCanNotSelect

                break;
            default: break;
        }

        return style
    }
    const style2 = (key, production) => {
        let style = styles.rect

        switch (key) {
            case "weight":
                    style = styles.propCanSelect
                break;
            case "type_id":
                style =
                    productions.find(p =>
                        p.weight === sp.weight && sp.weightSelected &&
                        p.type_id === production.type_id
                    ) ?
                        styles.propCanSelect
                        :
                        styles.propCanNotSelect
                break;
            case "additive_id":
                style =
                    productions.find(p =>
                        p.weight === sp.weight && sp.weightSelected &&
                        p.type_id === sp.type_id && sp.type_idSelected &&
                        p.additive_id === production.additive_id
                    ) ?
                        styles.propCanSelect
                        :
                        styles.propCanNotSelect
                break;
            default: break;
        }
        return style
    }

    const propertyClassName = (propertyKey, production) => {
        return sp?.[`${propertyKey}Selected`] ?
            `${style1(propertyKey, production)}`
            :
            `${style2(propertyKey, production)}`
    }

    const propertyText = (d) => {
        return propertyKey === "weight" ? `${Number(d[propertyKey])} g` :
            propertyKey === "type_id" ?
                productStore.types.find(t => t.id === d[propertyKey]).name :
                propertyKey === "additive_id" ?
                    productStore.additives.find(a => a.id === d[propertyKey]).name : ''
    }


    return (
        <div className={styles.selectorBlock}>
            {distinct(productions, propertyKey)
                .sort((a, b) => a[propertyKey] - b[propertyKey])
                .map((production) =>
                    <div key={production[propertyKey]?.toString()} className={styles.column}>
                        <div className={styles.selector}>
                            <div className={propertyClassName(propertyKey, production)}
                                 onClick={() => setSelectedProductions(propertyKey, production[propertyKey])}
                            >
                                {propertyText(production)}
                            </div>
                        </div>
                    </div>
                )}
        </div>

    )
};

export default PropertySelector;