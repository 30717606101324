import React from 'react';

const PrivacyPolicy = ({marginTop}) => {
    return (
        <div style={{margin:`${marginTop||16}px 16px 8px 32px`}}>
            <h1>Ochrana osobních údajů (GDPR)</h1>

            <h2>Správce osobních údajů</h2>
            <p>
                Správcem osobních údajů podle článku 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 (dále jen „GDPR“) je Nina Lifar, IČO: 17383013, se sídlem Jeremiasova 2722/2a, 15500 Praha (dále jen „správce“).
            </p>

            <h2>Uživatel</h2>
            <p>
                Uživatel je fyzická osoba, která využívá služby správce. Uživatel poskytuje své osobní údaje správci za účelem poskytování služeb a jejich zlepšování, komunikace, marketingu a zpracování dat.
            </p>

            <h2>Kontaktní údaje správce</h2>
            <p>
                Email: <a href="mailto:info@zivychleb.cz">info@zivychleb.cz</a><br />
                Adresa: Holandská 122, 266 01 Beroun
            </p>

            <h2>Zpracovávané osobní údaje</h2>
            <p>Správce zpracovává tyto osobní údaje:</p>
            <ul>
                <li>Jméno a příjmení</li>
                <li>Emailová adresa</li>
                <li>Telefonní číslo</li>
            </ul>

            <h2>Účel zpracování osobních údajů</h2>
            <p>Osobní údaje jsou zpracovávány za účelem:</p>
            <ul>
                <li>Poskytování služeb a jejich zlepšování</li>
                <li>Komunikace s uživateli</li>
                <li>Marketing</li>
                <li>Zpracování dat</li>
                <li>Plnění právních povinností správce</li>
            </ul>

            <h2>Právní základ pro zpracování osobních údajů</h2>
            <p>Právní základ pro zpracování osobních údajů je:</p>
            <ul>
                <li>Plnění právních povinností správce</li>
                <li>Oprávněný zájem správce na poskytování a zlepšování služeb</li>
            </ul>

            <h2>Doba uchovávání osobních údajů</h2>
            <p>
                Osobní údaje jsou uchovávány po dobu nezbytně nutnou k naplnění účelu zpracování, nejdéle však po dobu 10 let od ukončení využívání služeb.
            </p>

            <h2>Předávání osobních údajů třetím stranám</h2>
            <p>
                Správce může předávat osobní údaje třetím stranám pouze v souladu s právními předpisy a za účelem poskytování služeb nebo jejich zlepšování.
            </p>

            <h2>Práva uživatelů</h2>
            <p>Uživatelé mají právo:</p>
            <ul>
                <li>Na přístup k osobním údajům</li>
                <li>Na opravu nepřesných osobních údajů</li>
                <li>Na výmaz osobních údajů</li>
                <li>Na omezení zpracování osobních údajů</li>
                <li>Na přenositelnost osobních údajů</li>
                <li>Vznést námitku proti zpracování osobních údajů</li>
            </ul>

            <h2>Kontaktní údaje pro uplatnění práv</h2>
            <p>
                Uživatelé mohou uplatnit svá práva kontaktováním správce prostřednictvím emailu na adrese <a href="mailto:info@zivychleb.cz">info@zivychleb.cz</a>.
            </p>

            <h2>Zabezpečení osobních údajů</h2>
            <p>
                Správce přijímá vhodná technická a organizační opatření k zabezpečení osobních údajů proti neoprávněnému přístupu, ztrátě, zničení nebo poškození.
            </p>

            <h2>Závěrečná ustanovení</h2>
            <p>
                Tato politika ochrany osobních údajů je účinná od 1.12.2023 a může být pravidelně aktualizována. O změnách budete informováni prostřednictvím webových stránek správce.
            </p>
            <p>
                Pokud máte jakékoli dotazy ohledně zpracování osobních údajů, neváhejte nás kontaktovat.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
