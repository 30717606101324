import {$host} from "./index";

export const fetchProductionAll = async () => {
    const {data} = await $host.get('api/production')
    return data
}
export const getProductionAmount = async (id) => {
    const {data} = await $host.post('api/production/getAmount/' + id)
    return data
}
export const fetchProductionsAmounts = async  () =>{
    const {data} = await $host.get('api/production/amounts/')
    return data
}
