export class myArray extends Array {

    distinct(key) {
        return distinct(this, key)
    }

    isEmpty() {
        return this.length === 0;
    }

    groupBy(category) {
        return groupBy(this, category)
    }

    groupBy4(category1,category2,category3,category4) {
        return groupBy4(this,category1,category2,category3,category4)
    }

    groupBy5(category1, category2, category3, category4, category5) {
        return groupBy5(this, category1, category2, category3, category4, category5)
    }

    groupByObj(category) {
        return groupByObj(this,category)
    }
}

export function distinct(arr, key) {
    if (!arr) return []
    if (arr.length < 2) return arr
    let result = []
    for (const e of arr) {
        result.find(p => p[key] === e[key]) === undefined && result.push(e)
    }
    return result

}

export const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export const groupBy4 = (xs,key1,key2,key3,key4) => {
    return groupBy(xs.map(x=>({...x,conjunction:`${x[key1]},${x[key2]},${x[key3]},${x[key4]}`})),'conjunction')
}
export const groupBy5 = (xs,key1,key2,key3,key4,key5) => {
    return groupBy(xs.map(x=>({...x,conjunction:`${x[key1]},${x[key2]},${x[key3]},${x[key4]},${x[key5]}`})),'conjunction')
}
export const groupByObj = (xs,key) =>
     Object.entries(groupBy(xs,key)).map(x=> ({[key]:x[0],arr:x[1]}));


export const toPlain = response => {
    const flattenDataValues = ({dataValues}) => {
        const flattenedObject = {};

        Object.keys(dataValues).forEach(key => {
            const dataValue = dataValues[key];

            if (
                Array.isArray(dataValue) &&
                dataValue[0] &&
                dataValue[0].dataValues &&
                typeof dataValue[0].dataValues === 'object'
            ) {
                flattenedObject[key] = dataValues[key].map(flattenDataValues);
            } else if (dataValue && dataValue.dataValues && typeof dataValue.dataValues === 'object') {
                flattenedObject[key] = flattenDataValues(dataValues[key]);
            } else {
                flattenedObject[key] = dataValues[key];
            }
        });

        return flattenedObject;
    };

    if(!response.dataValues) return response
    return Array.isArray(response) ? response.map(flattenDataValues) : flattenDataValues(response);
};