import React, {useEffect, useState} from 'react';
import styles from "./css/ProductSelector.module.css";
import {dateToDDMMYY} from "../../../utils/dateTime";
import AmountBtn from "./AmountBtn";
import TimeSelector from "./TimeSelector";

const DateSelector1 = ({
                           production,
                           onHereDateClicked,
                           selectedProductions,
                           setAmountHandler,
                           setTimeHandler,
                           ...props
                       }) => {

    const [timeStyle, setTimeStyle] = useState(`${styles.timeInterval1} ${styles.muted}`)
    const [amountError, setAmountError] = useState('')
    const [errorColor, setErrorColor] = useState('var(--primary')

    let isIntervalSelected = Boolean(production?.issueInterval)
    const isSelectCompleted = Boolean(selectedProductions?.find(p =>
            p.weightSelected &&
            p.type_idSelected &&
            p.additive_idSelected
        )
    )

    useEffect(() => {
        isSelectCompleted ?
            production?.issueInterval ?
                setTimeStyle(`${styles.timeInterval1} ${styles.selected}`)
                :
                setTimeStyle(`${styles.timeInterval1} ${styles.possible}`)
            :
            setTimeStyle(`${styles.timeInterval1} ${styles.muted}`)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntervalSelected])

    const setAmountHandlerPlus = (p, v) => {
        if (isSelectCompleted) {
            p.issueInterval ?
                setAmountHandler(p, v)
                :
                setTimeStyle(`${styles.timeInterval1} ${styles.warning}`)
        }
    }

    const dateClass = isSelectCompleted ?
        production?.selectedDate ? `${styles.propSelected}` : `${styles.propCanSelect}`
        :
        `${styles.propCanNotSelect}`

    return (
        <div>
            <div className="mt-0 px-0 d-flex">
                {/* Date */}
                <div className={styles.name}>
                    Datum a čas
                </div>
                <div className={styles.column}>
                    <div className={styles.selector}>

                        <div className={dateClass}
                             onClick={() => onHereDateClicked(production)}>
                            {production?.selectedDate ? dateToDDMMYY(production?.selectedDate) : "Vybrat"}
                        </div>
                    </div>
                </div>

                {/* Time */}
                <div className={styles.column}>
                    <div className={styles.selector}>
                        <TimeSelector setAmountHandler={setAmountHandler}
                                      production={production}
                                      selectedProductions={selectedProductions}
                                      setTimeHandler={setTimeHandler}
                                      timeStyle={timeStyle}
                                      width={142}
                                      isSelectCompleted={isSelectCompleted}
                                      {...props}
                        />
                    </div>
                </div>

            </div>

            {/* Amount */}
            <div className="d-flex ">
                <div className={styles.nameD}>
                    <div >Množství</div>
                    <div className={styles.error}>{amountError}</div>
                </div>
                <div className={styles.columnAmount}>
                    <AmountBtn production={production}
                               selectedProductions={selectedProductions}
                               setAmountHandler={setAmountHandlerPlus}
                               amountError={amountError} errorColor={errorColor}
                               setAmountError={setAmountError}
                               setErrorColor={setErrorColor}
                               setTimeStyleWarning={setTimeStyle}
                               timeStyles={{timeStyleWarning: `${styles.timeInterval1} ${styles.warning}`, timeStyleNormal: `${styles.timeInterval1} ${styles.possible}`}}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateSelector1;