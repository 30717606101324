import React, {useContext} from 'react';
import styles from "./CheckoutBasket.module.css";
import {Image} from "react-bootstrap";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {myArray} from "../../utils/myArray";
import defaultPic from "../shopComponents/img/default-pic.svg"


const CheckoutBasket = observer(() => {
    const {basketStore} = useContext(Context)
    const basket = basketStore.basket

    const c = new myArray(...basket.basket_products)
        .groupBy4('product_id', 'variant_id', 'additive_id', 'type_id')

    const bpGroupBy = Object.values(c)?.map(p => ({
        ...p.at(0),
        number: p.reduce((sum, x) => sum + x.amount, 0), fullPrice: p.reduce((sum, x) => sum + x.price * x.amount, 0)
    }))

    const total = basket.basket_products.reduce((sum, bp) => sum + bp.amount * bp.price, 0)

    return (
        <div className={styles.checkoutBasket}>
            <div className={styles.basket}>

                <div className={styles.title}>Souhrn rezervací</div>
                <div className={styles.shadow}/>
                <div className={styles.cont}>
                    {bpGroupBy.map(bp =>
                        <div key={bp.id} className={styles.basketRect}>
                            <Image className={styles.basketImg}
                                   src={bp.product?.img ? process.env.REACT_APP_IMG_URL + bp.product.img : defaultPic}
                                   alt=""
                            />
                            <div
                                className={styles.text}>
                                {bp.number}x {bp.product?.name} {bp.variant?.weight}g {bp.type?.name} {bp.additive?.name}
                            </div>
                            <div className={styles.price}><span>{bp.fullPrice} Kč</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.textBody2}>
                    <div className={styles.sp}><span>Osobní odběr</span><span>zdarma</span></div>
                    <div className={styles.sp}><span>Platba na prodejně</span><span>zdarma</span></div>
                    <div className={styles.sp}><span>Papírová taška</span><span>zdarma</span></div>
                </div>
                <div className={styles.shadow}/>
            </div>

            <div className={styles.priceCont}>
                <span>Celková cena:</span>
                <div>{total} Kč</div>
            </div>
        </div>
    );
});

export default CheckoutBasket;